






import { Component, Vue } from 'vue-property-decorator';
import WordDictionary from './components/WordDictionary.vue';

@Component({
  name: 'CauseWords',
  components: {
    WordDictionary,
  },
})

export default class extends Vue {
}
